import React from "react";
import { FaMoon, FaSun, FaHeart, FaHeartBroken } from "react-icons/fa";

const Header = ({ darkMode, setDarkMode, showFavorites, setShowFavorites }) => {
  return (
    <header className="relative mb-8">
      {/* Dark mode toggle and favorites button in top-right */}
      <div className="absolute top-0 right-0 flex items-center space-x-4 p-4">
        <div 
          className="dark-mode-toggle cursor-pointer"
          onClick={() => setDarkMode(!darkMode)}
        >
          {darkMode ? (
            <FaSun className="text-[#ecf0f1] text-2xl" />
          ) : (
            <FaMoon className="text-[#1e1e1e] text-2xl" />
          )}
        </div>
        <div 
          className="cursor-pointer p-2"
          onClick={() => setShowFavorites(!showFavorites)}
        >
          {showFavorites ? (
            <FaHeartBroken className="text-2xl text-red-500" />
          ) : (
            <FaHeart className={`text-2xl ${darkMode ? "text-white" : "text-black"}`} />
          )}
        </div>
      </div>

      {/* Title and subtitle */}
      <div className="text-center">
        <h1 className="text-8xl font-bold mb-2 text-primary header-text">Stellar Search</h1>
        {/* <p className="text-xl text-secondary mb-4">
          A Galaxy of Free Audiobooks
        </p> */}
      </div>
    </header>
  );
};

export default Header;
