// NoResults.jsx
import React from "react";

const NoResults = ({ noResults }) => {
  return noResults ? (
    <p className="no-results">
      No results found. Try a different search term.
    </p>
  ) : null;
};

export default NoResults;
