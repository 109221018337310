// Filter.jsx
import React from "react";

const Filter = ({ humanOnly, setHumanOnly }) => {
  return (
    <div className="filter hidden">
      <label>
        <input
          type="checkbox"
          checked={humanOnly}
          onChange={(e) => setHumanOnly(e.target.checked)}
        />
        Human Narrations Only
      </label>
    </div>
  );
};

export default Filter;
