import React from 'react';

const BuyMeCoffeeButton = () => {
  const buttonStyle = {
    position: 'fixed',
    top: '20px',
    left: '20px',
    zIndex: 9999,
    backgroundColor: '#5F7FFF',
    color: '#ffffff',
    border: 'none',
    borderRadius: '5px',
    padding: '10px 20px',
    fontFamily: 'Cookie, cursive',
    fontSize: '18px',
    cursor: 'pointer',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    transition: 'all 0.3s ease'
  };

  return (
    <a 
      href="https://www.buymeacoffee.com/p_hoep" 
      target="_blank" 
      rel="noopener noreferrer"
      style={buttonStyle}
    >
      ☕ Buy me a coffee
    </a>
  );
};

export default BuyMeCoffeeButton;