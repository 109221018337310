import React from "react";
import { FaDownload, FaHeart, FaHeartBroken, FaSearch, FaBook, FaPlay } from "react-icons/fa";

const BookCard = ({
  book,
  details,
  toggleFavorite,
  handleSimilarSearch,
  isFavorite,
  handlePlayAudio,
}) => {
  const isFav = isFavorite(book.id);

  return (
    <div key={book.id} className="book-card">
      {details.Cover_url && (
        <img
          src={details.Cover_url}
          alt={`Cover for ${details.Title}`}
          className="book-cover"
        />
      )}
      <h3 className="book-title">{details.Title}</h3>
      <p className="book-description">{details.Description}</p>
      <div className="book-actions">
        {details.Url && (
          <button
            onClick={() => handlePlayAudio(details.Url)}
            className="link-button play-button"
          >
            <FaPlay />
          </button>
        )}
        {details.Url && (
          <a
            href={details.Url}
            download
            className="link-button download-button"
          >
            <FaDownload />
          </a>
        )}
        {details.Gutenberg_id && (
          <a
            href={`https://www.gutenberg.org/ebooks/${details.Gutenberg_id}`}
            target="_blank"
            rel="noopener noreferrer"
            className="link-button"
          >
            <FaBook />
          </a>
        )}
        <button className="link-button" onClick={() => toggleFavorite(book)}>
          {isFav ? <FaHeartBroken /> : <FaHeart />}
        </button>
        <button
          className="link-button"
          onClick={() => handleSimilarSearch(book.id)}
        >
          <FaSearch />
        </button>
      </div>
    </div>
  );
};

export default BookCard;