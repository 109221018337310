import React, { useState, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import BookCard from "./BookCard";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const ResultsContainer = ({
  results,
  bookDetails,
  isLoadingDetails,
  toggleFavorite,
  handleSimilarSearch,
  isFavorite,
  handlePlayAudio,
}) => {
  const [visibleBooks, setVisibleBooks] = useState([]);
  const [fadingBooks, setFadingBooks] = useState([]);
  const prevVisibleBooksRef = useRef([]);

  useEffect(() => {
    const newResultIds = results.map(result => result.id);
    const oldResultIds = prevVisibleBooksRef.current;

    if (JSON.stringify(newResultIds) !== JSON.stringify(oldResultIds)) {
      setFadingBooks(oldResultIds);
      setVisibleBooks([]);

      setTimeout(() => {
        setFadingBooks([]);
        setVisibleBooks(newResultIds);
      }, 500); // Adjust this timing to match your CSS transition duration
    }

    prevVisibleBooksRef.current = newResultIds;
  }, [results]);

  return (
    <div className="swiper-container">
      <Swiper
        modules={[Pagination, Navigation]}
        loop={true}
        spaceBetween={20}
        breakpoints={{
          320: { slidesPerView: 1, spaceBetween: 10 },
          640: { slidesPerView: 2, spaceBetween: 20 },
          768: { slidesPerView: 3, spaceBetween: 20 },
          1024: { slidesPerView: 4, spaceBetween: 30 },
          1280: { slidesPerView: 5, spaceBetween: 30 },
          1536: { slidesPerView: 6, spaceBetween: 30 },
        }}
        pagination={{ clickable: true }}
        navigation
        className="mySwiper"
      >
        {results.map((result) => {
          const details = bookDetails[result.id];
          const isVisible = visibleBooks.includes(result.id);
          const isFading = fadingBooks.includes(result.id);

          return (
            <SwiperSlide key={result.id}>
              {isLoadingDetails ? (
                <div className="card-loading">
                  {/* <div className="spinner spinner-results"></div> */}
                </div>
              ) : details ? (
                <div className={`book-card-container ${isVisible ? 'fade-in' : ''} ${isFading ? 'fade-out' : ''}`}>
                  <BookCard
                    book={result}
                    details={details}
                    toggleFavorite={toggleFavorite}
                    handleSimilarSearch={handleSimilarSearch}
                    isFavorite={isFavorite}
                    handlePlayAudio={handlePlayAudio}
                  />
                </div>
              ) : null}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default ResultsContainer;