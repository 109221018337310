import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import Plot from 'react-plotly.js';

async function loadNDJSON(fileUrl) {
  const response = await fetch(fileUrl);
  const reader = response.body.getReader();
  const decoder = new TextDecoder("utf-8");
  let ndjson = "";
  let done = false;

  while (!done) {
    const { value, done: readerDone } = await reader.read();
    done = readerDone;
    ndjson += decoder.decode(value || new Uint8Array(), { stream: !readerDone });
  }

  return ndjson
    .split("\n")
    .filter(Boolean)
    .map((line) => JSON.parse(line));
}

async function loadJSON(fileUrl) {
  const response = await fetch(fileUrl);
  return response.json();
}

const EmbeddingVisualizer = React.memo(({ fileUrl, highlightIds = [], zoomFactor = 2, darkMode, visible = true }) => {
  const [baseData, setBaseData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [jsonData, setJsonData] = useState([]);
  const datasetRef = useRef(null);
  const animationRef = useRef(null);
  const [angles, setAngles] = useState({ x: 0, y: 0, z: 0 });
  const [highlightedIds, setHighlightedIds] = useState([]); // State for highlighted random IDs
  const [fadeIn, setFadeIn] = useState(0); // State for fade-in effect

  const layout = useMemo(() => ({
    scene: {
      xaxis: { showgrid: false, zeroline: false, showline: false, ticks: '', showticklabels: false },
      yaxis: { showgrid: false, zeroline: false, showline: false, ticks: '', showticklabels: false },
      zaxis: { showgrid: false, zeroline: false, showline: false, ticks: '', showticklabels: false },
      camera: { 
        eye: { 
          x: 1.5 / zoomFactor * Math.cos(angles.x) * Math.cos(angles.y),
          y: 1.5 / zoomFactor * Math.sin(angles.x) * Math.cos(angles.y),
          z: 1.5 / zoomFactor * Math.sin(angles.y)
        },
        up: { x: 0, y: 0, z: 1 }
      },
      aspectmode: 'cube',
    },
    paper_bgcolor: 'rgba(0,0,0,0)',
    plot_bgcolor: 'rgba(0,0,0,0)',
    showlegend: false,
    autosize: true,
    margin: { l: 0, r: 0, t: 0, b: 0 },
  }), [zoomFactor, angles]);

  const fetchData = useCallback(async () => {
    if (!datasetRef.current) {
      const embeddings = await loadNDJSON(fileUrl);
      datasetRef.current = embeddings;
      const jsonDataResult = await loadJSON('/data.json');
      setJsonData(jsonDataResult);

      const x = embeddings.map(e => e.values[0]);
      const y = embeddings.map(e => e.values[1]);
      const z = embeddings.map(e => e.values[2]);
      const ids = embeddings.map(e => e.id);

      setBaseData({ x, y, z, ids });

      // Randomly select 10 IDs to highlight only when there are no external highlights
      if (highlightIds.length === 0) {
        const randomIds = ids.sort(() => 0.5 - Math.random()).slice(0, 10);
        setHighlightedIds(randomIds);  // Store them in state
      } else {
        setHighlightedIds([]); // Clear random highlights if external highlights are provided
      }
    }
    setIsLoading(false);
  }, [fileUrl, highlightIds]);

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [fetchData]);

  // Fade-in effect when data is loaded
  useEffect(() => {
    if (!isLoading) {
      setFadeIn(0); // Start with 0 opacity
      const timer = setTimeout(() => setFadeIn(1), 100); // Gradually set to 1
      return () => clearTimeout(timer);
    }
  }, [isLoading]);

  const data = useMemo(() => {
    if (!baseData) return [];

    const { x, y, z, ids } = baseData;

    const sizes = ids.map(() => Math.random() * 1.5 + 0.5);

    const pointColor = darkMode ? 'rgba(200, 200, 200, 0.1)' : 'rgba(50, 50, 50, 1.0)';
    const highlightColor = darkMode ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 1.0)';

    // Use passed `highlightIds` if they exist, otherwise use random `highlightedIds`
    const activeHighlightIds = highlightIds.length > 0 ? highlightIds : highlightedIds;

    const colors = ids.map(id => (activeHighlightIds.includes(id) ? highlightColor : pointColor));

    const markerData = {
      x, y, z,
      mode: 'markers',
      type: 'scatter3d',
      marker: { size: sizes, color: colors, opacity: 0.5 },
      hoverinfo: 'none',
    };

    const highlightIndices = ids
      .map((id, index) => (activeHighlightIds.includes(id) ? index : null))
      .filter(index => index !== null);

    // Connect highlighted points in a loop
    const linesBetweenPoints = [];
    for (let i = 0; i < highlightIndices.length; i++) {
      const startIndex = highlightIndices[i];
      const endIndex = highlightIndices[(i + 1) % highlightIndices.length]; // Wrap around to first index
      linesBetweenPoints.push({
        x: [x[startIndex], x[endIndex]],
        y: [y[startIndex], y[endIndex]],
        z: [z[startIndex], z[endIndex]],
        mode: 'lines',
        type: 'scatter3d',
        line: { color: highlightColor, width: 1, opacity: 0.3 },
        hoverinfo: 'none',
      });
    }

    const titleLabelTraces = activeHighlightIds.map((id) => {
      const index = ids.indexOf(id);
      const jsonItem = jsonData.find(item => item.Id === id);
      if (jsonItem && jsonItem.Title) {
        return {
          x: [x[index]], y: [y[index]], z: [z[index]],
          mode: 'text',
          type: 'scatter3d',
          text: [jsonItem.Title],
          textposition: 'middle center',
          textfont: {
            color: highlightColor,
            size: 10,
          },
          hoverinfo: 'none',
        };
      }
      return null;
    }).filter(Boolean);

    return [markerData, ...linesBetweenPoints, ...titleLabelTraces];
  }, [baseData, highlightIds, highlightedIds, jsonData, darkMode]);

  useEffect(() => {
    const animate = () => {
      setAngles(prevAngles => ({
        x: prevAngles.x + 0.0002,
        y: prevAngles.y + 0.0002,
        z: prevAngles.z + 0.0002
      }));
      animationRef.current = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, []);

  if (!visible || (isLoading && !baseData)) {
    return null;
  }

  return (
    <div
      style={{
        backgroundColor: darkMode ? '#000' : '#fff',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex: '-1',
        opacity: fadeIn,
        transition: 'opacity 1s ease-in-out' // Fade-in effect
      }}
    >
      <Plot
        data={data}
        layout={layout}
        config={{
          displayModeBar: false,
          responsive: true,
        }}
        style={{ width: '100%', height: '100%' }}
        useResizeHandler={true}
      />
      <div 
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          opacity: 0.05,
          backgroundColor: 'transparent',
          pointerEvents: 'auto',
        }}
      />
    </div>
  );
});

export default EmbeddingVisualizer;
