// SearchForm.jsx
import React from "react";
// import { FaSearch } from "react-icons/fa";

// const MIN_QUERY_LENGTH = 3;

const SearchForm = ({ searchTerm, setSearchTerm, handleSearch }) => {
  return (
    <form onSubmit={handleSearch} className="search-container">
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder={`Search for free audiobooks...`}
      />
      {/* <button type="submit">
        <FaSearch /> Search
      </button> */}
    </form>
  );
};

export default SearchForm;