// LoadingOverlay.jsx
import React from "react";

const LoadingOverlay = () => {
  return (
    <div className="loading-overlay">
      <div className="spinner"></div>
      <p>Searching...</p>
    </div>
  );
};

export default LoadingOverlay;
