import React, { useState, useEffect, useCallback, useRef } from "react";
import { Howl } from "howler";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlay,
  faPause,
  faForward,
  faBackward,
} from "@fortawesome/free-solid-svg-icons";

const CombinedAudioPlayer = ({ url, isDarkMode }) => {
  const [playlist, setPlaylist] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTrack, setCurrentTrack] = useState(0);
  const [howl, setHowl] = useState(null);
  const [duration, setDuration] = useState(0);
  const [seek, setSeek] = useState(0);
  const [isSeeking, setIsSeeking] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);
  const seekBarRef = useRef(null);
  const hasStartedRef = useRef(false);
  const loadingToastId = useRef(null);
  const proxyUrl = "https://abook-proxy.16522c10-f242-4259-99e3-d2ed66bee984.workers.dev/";

  const fetchAudioContent = useCallback(async () => {
    if (!url) return;
    setIsLoading(true);
    setIsPlaying(false);
    hasStartedRef.current = false;
    console.log("Fetching audio content:", url);
    loadingToastId.current = toast.loading(
      <div>Loading audio content...</div>,
      { autoClose: false }
    );
    try {
      let newPlaylist = [];
      if (url.endsWith(".m3u") || url.endsWith(".m3u8")) {
        const response = await axios.get(
          `${proxyUrl}?url=${encodeURIComponent(url)}`
        );
        if (!response || !response.data) {
          throw new Error("Empty response or response missing 'data' field");
        }
        newPlaylist = response.data
          .split("\n")
          .filter((line) => line && !line.startsWith("#"))
          .map((line) => line.trim());
      } else {
        newPlaylist = [url];
      }
      setPlaylist(newPlaylist);
    } catch (error) {
      console.error("Error loading audio content:", error);
      toast.error("Error loading audio...");
      toast.dismiss(loadingToastId.current);
    } finally {
      setIsLoading(false);
    }
  }, [url]);

  useEffect(() => {
    fetchAudioContent();
  }, [url, fetchAudioContent]);

  useEffect(() => {
    if (playlist.length > 0) {
      const newHowl = new Howl({
        src: [playlist[currentTrack]],
        html5: true,
        preload: true,
        onload: () => {
          setDuration(newHowl.duration());
          if (!hasStartedRef.current) {
            newHowl.play();
            hasStartedRef.current = true;
            toast.dismiss(loadingToastId.current);
            toast.success("Audio playback started");
          }
        },
        onplay: () => setIsPlaying(true),
        onpause: () => setIsPlaying(false),
        onend: () => {
          if (currentTrack < playlist.length - 1) {
            setCurrentTrack(currentTrack + 1);
          } else {
            setIsPlaying(false);
            hasStartedRef.current = false;
          }
        },
        onloaderror: (id, error) => {
          console.error("Error loading audio:", error);
          toast.error("Error loading audio. Please try again.");
          toast.dismiss(loadingToastId.current);
          hasStartedRef.current = false;
        },
        onplayerror: (id, error) => {
          console.error("Error playing audio:", error);
          toast.error("Error playing audio. Please try again.");
          toast.dismiss(loadingToastId.current);
          hasStartedRef.current = false;
        },
      });
      setHowl(newHowl);
      return () => {
        newHowl.unload();
        hasStartedRef.current = false;
      };
    }
  }, [playlist, currentTrack]);

  useEffect(() => {
    const updateSeek = () => {
      if (howl && isPlaying && !isSeeking) {
        setSeek(howl.seek());
      }
    };
    const intervalId = setInterval(updateSeek, 1000);
    return () => clearInterval(intervalId);
  }, [howl, isPlaying, isSeeking]);

  useEffect(() => {
    if (howl) {
      howl.rate(playbackRate);
    }
  }, [howl, playbackRate]);

  const togglePlayPause = () => {
    if (howl) {
      if (isPlaying) {
        howl.pause();
      } else {
        howl.play();
        hasStartedRef.current = true;
      }
    }
  };

  const changeTrack = (direction) => {
    if (direction === "next" && currentTrack < playlist.length - 1) {
      setCurrentTrack(currentTrack + 1);
      hasStartedRef.current = false;
    } else if (direction === "prev" && currentTrack > 0) {
      setCurrentTrack(currentTrack - 1);
      hasStartedRef.current = false;
    }
  };

  const handleSeekChange = (e) => {
    const newSeek = parseFloat(e.target.value);
    setSeek(newSeek);
  };

  const handleSeekStart = () => {
    setIsSeeking(true);
  };

  const handleSeekEnd = () => {
    setIsSeeking(false);
    if (howl) {
      howl.seek(seek);
    }
  };

  const changePlaybackRate = () => {
    const rates = [0.5, 1.0, 1.5, 2.0, 2.5, 3.0];
    const currentIndex = rates.indexOf(playbackRate);
    const nextIndex = (currentIndex + 1) % rates.length;
    setPlaybackRate(rates[nextIndex]);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  return (
    <div className={`audio-player-container ${isDarkMode ? "dark" : "light"}`}>
      <ToastContainer />
      {!isLoading && playlist.length > 0 && (
        <div className="player-controls">
          <div className="track-info">
            <h2 className="track-title">
              {playlist[currentTrack].split("/").pop().split(".")[0]}
            </h2>
            {/* <p className="track-artist">Famous Dex</p> */}
          </div>
          <div className="seek-container">
            <input
              type="range"
              min="0"
              max={duration}
              value={seek}
              onChange={handleSeekChange}
              onMouseDown={handleSeekStart}
              onMouseUp={handleSeekEnd}
              onTouchStart={handleSeekStart}
              onTouchEnd={handleSeekEnd}
              ref={seekBarRef}
              className="seek-bar"
            />
            <div className="time-display">
              <span>{formatTime(seek)}</span>
              <span>{formatTime(duration)}</span>
            </div>
          </div>
          <div className="control-buttons">
            <button onClick={changePlaybackRate} className="control-button">
              {playbackRate}x
            </button>
            <button
              onClick={() => changeTrack("prev")}
              disabled={currentTrack === 0}
              className="control-button"
            >
              <FontAwesomeIcon icon={faBackward} />
            </button>
            <button
              onClick={togglePlayPause}
              className="control-button play-pause"
            >
              <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
            </button>
            <button
              onClick={() => changeTrack("next")}
              disabled={currentTrack === playlist.length - 1}
              className="control-button"
            >
              <FontAwesomeIcon icon={faForward} />
            </button>
          </div>
        </div>
      )}
      <style jsx>{`
        .audio-player-container {
          background-color: ${isDarkMode ? "#1a1a1a" : "#ffffff"};
          color: ${isDarkMode ? "#ffffff" : "#000000"};
          padding: 20px;
          border-radius: 10px;
          max-width: 400px;
          margin: 0 auto;
        }
        .player-controls {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 20px;
        }
        .track-info {
          text-align: center;
        }
        .track-title {
          font-size: 24px;
          margin: 0;
        }
        .track-artist {
          font-size: 18px;
          margin: 5px 0 0;
          opacity: 0.8;
        }
        .seek-container {
          width: 100%;
        }
        .seek-bar {
          width: 100%;
          -webkit-appearance: none;
          appearance: none;
          height: 4px;
          background: ${isDarkMode
            ? "rgba(255, 255, 255, 0.3)"
            : "rgba(0, 0, 0, 0.3)"};
          outline: none;
          margin-bottom: 10px;
        }
        .seek-bar::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: 12px;
          height: 12px;
          background: ${isDarkMode ? "#ffffff" : "#000000"};
          cursor: pointer;
          border-radius: 50%;
        }
        .seek-bar::-moz-range-thumb {
          width: 12px;
          height: 12px;
          background: ${isDarkMode ? "#ffffff" : "#000000"};
          cursor: pointer;
          border-radius: 50%;
        }
        .time-display {
          display: flex;
          justify-content: space-between;
          font-size: 12px;
        }
        .control-buttons {
          display: flex;
          justify-content: space-between;
          width: 100%;
          max-width: 300px;
        }
        .control-button {
          background-color: transparent;
          color: ${isDarkMode ? "#ffffff" : "#000000"};
          border: none;
          font-size: 24px;
          cursor: pointer;
          transition: opacity 0.3s ease;
        }
        .control-button:hover {
          opacity: 0.7;
        }
        .control-button:disabled {
          opacity: 0.3;
          cursor: not-allowed;
        }
        .play-pause {
          font-size: 36px;
        }
      `}</style>
    </div>
  );
};

export default CombinedAudioPlayer;
