import React from "react";
import BookCard from "./BookCard"; // Assuming BookCard is in the same folder

const FavoritesContainer = ({ favorites, favoriteDetails, toggleFavorite, handleSimilarSearch, isFavorite, handlePlayAudio }) => {
  return (
    <div className="favorites-container">
      <h2>Your Favorites</h2>
      <div className="favorites-grid">
        {favorites.length === 0 ? (
          <p>No favorite books yet.</p>
        ) : (
          favorites.map(favId => {
            const details = favoriteDetails[favId];

            // Check if details are available for this favorite
            if (!details || Object.keys(details).length === 0) {
              return null;
            }

            return (
              <BookCard
                key={favId}
                book={{ id: favId }}  // Passing book ID as book prop
                details={details}     // Passing the details to BookCard
                toggleFavorite={toggleFavorite}
                handleSimilarSearch={handleSimilarSearch}
                isFavorite={isFavorite}
                handlePlayAudio={handlePlayAudio}
              />
            );
          })
        )}
      </div>
    </div>
  );
};

export default FavoritesContainer;
