import React, { useState } from 'react';
import axios from 'axios';

const OpenLibrarySearch = ({ onSearch }) => {
  const [bookTitle, setBookTitle] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSearch = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      // First, search for the book on OpenLibrary
      const openLibraryResponse = await axios.get(`https://openlibrary.org/search.json?title=${encodeURIComponent(bookTitle)}&limit=1`);
      
      if (openLibraryResponse.data.docs.length === 0) {
        throw new Error('Book not found on OpenLibrary');
      }

      const bookKey = openLibraryResponse.data.docs[0].key;
      
      // Then, fetch the book details to get the description
      const bookDetailsResponse = await axios.get(`https://openlibrary.org${bookKey}.json`);
      
      let description = bookDetailsResponse.data.description;
      if (typeof description === 'object' && description.value) {
        description = description.value;
      }

      if (!description) {
        throw new Error('No description available for this book');
      }

      // Finally, use the description to search your database
      onSearch(description);
    } catch (error) {
      setError(error.message || 'An error occurred while searching. Please try again.');
    }

    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSearch}>
      <input
        type="text"
        value={bookTitle}
        onChange={(e) => setBookTitle(e.target.value)}
        placeholder="Enter a book title"
        required
      />
      <button type="submit" disabled={isLoading}>
        {isLoading ? 'Searching...' : 'Search'}
      </button>
      {error && <p className="error">{error}</p>}
    </form>
  );
};

export default OpenLibrarySearch;